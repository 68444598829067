import React from "react";
import "./App.css";
import "./Media.css";
import Visualizer from "./components/Visualizer";

function App() {
  return (
    <>
      <Visualizer />
    </>
  );
}

export default App;
